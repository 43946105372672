import React from 'react';
import { NextPage } from 'next';

import { ContentfulLayout } from '@belong/ui-sections';
import { FPage, SectionParser } from '@belong/contentful';
import { logger } from '@belong/logging/logger';
import { IPage } from '@belong/types/page';
import { withBasePath, withoutParams } from '@belong/url-utils';

import ContentAPI from 'src/services/ContentAPI';
import { makeSectionParser } from 'src/models/page';
import { ISupportPageData, ISupportServerSidePageProps, PageServerSidePropsFunction } from 'src/core/types';
import { withServerSideAppProps } from 'src/helpers/config/withServerSideAppProps';

const HomePage: NextPage<ISupportPageData> = ({ data: { sections } }: ISupportPageData) => {
  return <ContentfulLayout sections={sections} />;
};

const getServerSidePageProps: PageServerSidePropsFunction = async (context, { config }) => {
  const pageUrl = withBasePath(withoutParams(context.resolvedUrl));

  let data: IPage;
  try {
    const pageContent = await ContentAPI.getPage(pageUrl);
    const sectionParser: SectionParser = makeSectionParser(config.categories);
    data = await FPage(pageContent, sectionParser);
  } catch (err) {
    logger.error(`Failed to load Contentful data for ${pageUrl}`);
    data = {
      identifier: 'no-content',
      pageName: '',
      pageTitle: 'Help and Support',
      keywords: '',
      description: '',
      sections: [],
      content: [],
      pageUrl: '',
      journeyName: '',
      flowName: '',
      meta: {
        entryId: ''
      }
    };
  }

  const { pageTitle, keywords, description, flowName, journeyName, pageName, ...pageData } = data;
  const homePageProps: ISupportServerSidePageProps = {
    meta: {
      pageTitle,
      keywords,
      description,
      flowName,
      journeyName,
      pageName
    },
    data: pageData
  };
  return homePageProps;
};

export const getServerSideProps = withServerSideAppProps(getServerSidePageProps);

export default HomePage;
